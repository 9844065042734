let routes = [
    {
        path: '/',
        name: 'product',
        redirect: '/home',
        component: () => import("../views/layout/Index.vue"),
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import("../views/home/HomeView.vue"),
            },
            {
                path: '/product',
                name: 'product',
                component: () => import("../views/product/Index.vue"),
                children: [
                    {
                        path: '/product/productLibrary',
                        name: 'productLibrary',
                        component: () => import("../views/product/ProductLibrary.vue"),
                    },
                    {
                        path: '/product/addProguct',
                        name: 'addProguct',
                        component: () => import("../views/product/AddProguct.vue"),
                    },
                    {
                        path: '/product/productDetails',
                        name: 'productDetails',
                        component: () => import("../views/product/ProductDetails.vue"),
                    },
                ]
            },
            // 改造对象
            {
                path: '/renovation',
                name: 'renovation',
                component: () => import("../views/renovation/Index.vue"),
                children: [
                    {
                        path: '/renovation/renovationObject',
                        name: 'renovationObject',
                        component: () => import("../views/renovation/RenovationObject.vue"),
                    },
                    // 新增改造对象
                    {
                        path: '/renovation/addRenovationObject',
                        name: 'addRenovationObject',
                        component: () => import("../views/renovation/AddConsumer.vue"),
                    },
                    // 环境评估
                    {
                        path: '/renovation/environmentalAssess',
                        name: 'environmentalAssess',
                        component: () => import("../views/renovation/EnvironmentalAssess.vue"),
                    },
                    // 能力评估
                    {
                        path: '/renovation/capabilityAssessment',
                        name: 'capabilityAssessment',
                        component: () => import("../views/renovation/CapabilityAssessment.vue"),
                    },
                    {
                        path: '/renovation/infoSetting',
                        name: 'infoSetting',
                        component: () => import("../views/renovation/InfoSetting.vue"),
                    },
                    {
                        path: '/renovation/renovationProject',
                        name: 'renovationProject',
                        component: () => import("../views/renovation/RenovationProject.vue"),
                    },
                    {
                        path: '/renovation/bindPerson',
                        name: 'bindPerson',
                        component: () => import("../views/renovation/BindPerson.vue"),
                    },
                    {
                        path: '/renovation/renovationReport',
                        name: 'renovationReport',
                        component: () => import("../views/renovation/RenovationReport.vue"),
                    },
                    {
                        path: '/renovation/editReport',
                        name: 'editReport',
                        component: () => import("../components/renovation/Report.vue"),
                    },
                    {
                        path: '/renovation/renovationExamine',
                        name: 'renovationExamine',
                        component: () => import("../views/renovation/RenovationExamine.vue"),
                    },
                    {
                        path: '/renovation/reportExamine',
                        name: 'reportExamine',
                        component: () => import("../components/renovation/ReportExamine.vue"),
                    },
                ]
            },// 改造对象
            {
                path: '/system',
                name: 'system',
                component: () => import("../views/renovation/Index.vue"),
                children: [
                    {
                        path: '/system/userManage',
                        name: 'userManage',
                        component: () => import("../views/system/UserManage.vue"),
                    },
                    {
                        path: '/system/dictionaryManage',
                        name: 'dictionaryManage',
                        component: () => import("../views/system/DictionaryManage.vue"),
                    },
                    {
                        path: '/system/wxUser',
                        name: 'wxUser',
                        component: () => import("../views/system/WxUser.vue"),
                    },
                ]
            },
        ]
    }, ,
    {
        path: '/login',
        name: 'login',
        meta: {
            requireAuth: true
        },
        component: () => import("../views/login/Index.vue"),
    },
    {
        path: '/inventory',
        name: 'inventory',
        meta: {
            requireAuth: true
        },
        component: () => import("../views/aaaa/Index.vue"),
    }
]

export default routes;