import http, { get, post } from "@/core/mixins/useRequest";
// import http, { get, post } from "@core/require";

// 获取验证码
// export const getCaptchaApi = (params) => {
//   return http({
//     url: "/api/blade-auth/captcha",
//     method: "GET",
//     params,
//   });
// };

export const login = (params) => {
  return get("/user/login", params);
};
export const userCancel = (params) => {
  return get("/user/cancel", params);
};
export const modifypswd = (params) => {
  return get("/user/modifypswd", params);
};
export const resetpswd = (params) => {
  return get("/user/resetpswd", params);
};

// index
// 菜单接口
export const menu = (params) => {
  return get("/index/menu", params);
};
// 基础信息展示接口
export const total = (params) => {
  return get("/index/total", params);
};
// 首页信息展示接口
export const info = (params) => {
  return get("/index/info", params);
};
// 首页信息展示接口
export const consumerReportQueryGGid = (params) => {
  return get("/consumerReport/queryGGid", params);
};







export const list = (params) => {
  return get("/area/list", params);
};
// 获取工单服务列表
export const Snifft = (params) => {
  return get("/service_info/Sniff", params);
};



// 获取产品库列表
export const productList = (params) => {
  return get("/product/list", params);
};
// 图片上传
export const upload = (params) => {
  return post("/aliyun/oss/upload", params);
};
// 图片上传
export const quickUpload = (params) => {
  return post("/aliyun/oss/quickUpload", params);
};
// 图片上传
export const consumerReportImport = (params) => {
  return post("/consumerReport/import", params);
};
// 图片删除
export const productImgDelete = (params) => {
  return get("/productImg/delete", params);
};


// 产品新增接口
export const productAdd = (params) => {
  return post("/product/add", params);
};
// 查看产品详情
export const productfindById = (params) => {
  return get("/product/findById", params);
};
// 停用产品
export const deactivate = (params) => {
  return get("/product/deactivate", params);
};
// 修改产品
export const edit = (params) => {
  return post("/product/edit", params);
};


// 改造对象
// 新增改造对象
export const consumerAdd = (params) => {
  return post("/consumer/add", params);
};
// 修改改造对象
export const consumerUpdate = (params) => {
  return post("/consumer/update", params);
};
// 新增改造对象
export const consumerList = (params) => {
  return get("/consumer/list", params);
};
// 根据ID查找服务对象
export const consumerQueryById = (params) => {
  return get("/consumer/queryById", params);
};




// 新增改造项目
export const remodel_projectAdd = (params) => {
  return post("/remodel_project/add", params);
};

// 新增改造项目
export const queryAll = (params) => {
  return get("/aEstimateSore/queryEstimateAndScore", params);
};
// 新增改造项目
export const queryNameAndScore = (params) => {
  return get("/aEstimateSore/queryNameAndScore", params);
};
// 新增改造项目
export const queryByConsumerId = (params) => {
  return get("/aEstimateSore/queryByConsumerId", params);
};

// 新增改造项目
export const aEstimateSoreAdd = (params) => {
  return post("/aEstimateSore/add", params);
};
// 新增改造项目
export const aEstimateSoreEdit = (params) => {
  return post("/aEstimateSore/edit", params);
};

// 新增改造项目
export const eEstimateQueryEstimateAndScore = (params) => {
  return get("/eEstimateScore/queryEstimateAndScore", params);
};
// 新增改造项目
export const eEstimateScoreQueryByConsumerId = (params) => {
  return get("/eEstimateScore/queryByConsuerId", params);
};
// 新增改造项目
export const eEstimateScoreAdd = (params) => {
  return post("/eEstimateScore/add", params);
};
// 新增改造项目
export const eEstimateScoreEdit = (params) => {
  return post("/eEstimateScore/edit", params);
};
// 根据关键字查询
export const dictList = (params) => {
  return get("/dict/list", params);
};
// 根据关键字查询
export const dictInsert = (params) => {
  return post("/dict/insert", params);
};
// 根据关键字查询
export const dictUpdate = (params) => {
  return post("/dict/update", params);
};
// 根据关键字查询
export const dictQueryById = (params) => {
  return get("/dict/queryById", params);
};
// 根据关键字查询
export const reportMake = (params) => {
  return get("/report/make", params);
};
// 根据关键字查询
export const reportQueryById = (params) => {
  return get("/report/queryById", params);
};
// 根据关键字查询
export const reportDeleteById = (params) => {
  return get("/report/deleteById", params);
};
// 根据关键字查询
export const findUserList = (params) => {
  return get("user/findUserList", params);
};
// 首页相关接口
// 首页数据统计
export const statistics = (params) => {
  return get("/index/statistics", params);
};
// 查询所有评估信息
export const pEvaluateQueryAll = (params) => {
  return get("/pEvaluate/queryAll", params);
};
// 新增项目评估信息
export const pEvaluateAdd = (params) => {
  return post("/pEvaluate/add", params);
};



export const consumerReportQueryAllId = (params) => {
  return get("/consumerReport/queryAllId", params);
};




export const aEstimateQueryAll = (params) => {
  return get("/aEstimate/queryAll", params);
};
// 新增改造项目
export const eEstimateQueryAll = (params) => {
  return post("/eEstimate/queryAll", params);
};
// 根据产品定价id查找能力评估产品定价
export const aEstimateProjectQueryById = (params) => {
  return get("/aEstimateProject/queryById", params);
};
// 新增评估产品定价
export const aEstimateProjectAdd = (params) => {
  return post("/aEstimateProject/add", params);
};

// 微信小程序用户相关接口
// 查询服务人员列表
export const wxUserList = (params) => {
  return get("/wxUser/list", params);
};
// 新增小程序账户
export const wxUserAdd = (params) => {
  return post("/wxUser/add", params);
};
// 删除
export const wxUserDelete = (params) => {
  return post("/wxUser/delete", params);
};
// 删除
export const wxUserResetPassword = (params) => {
  return get("/wxUser/resetPassword", params);
};
// 删除
export const wxUserNameList = (params) => {
  return get("/wxUser/nameList", params);
};
// 删除
export const consumerNameList = (params) => {
  return get("/consumer/nameList", params);
};
// 删除
export const wxUserQueryConsumerByWxId = (params) => {
  return get("/wxUser/queryConsumerByWxId", params);
};
// 删除
export const consumerBindProvider = (params) => {
  return post("/consumer/bindProvider", params);
};
// 删除
export const consumerProjectUpdateById = (params) => {
  return post("/consumerProject/updateById", params);
};
// 删除
export const consumerReportQueryAuditList = (params) => {
  return get("/consumerReport/queryAuditList", params);
};
// 删除
export const consumerProjectQueryAuditListById = (params) => {
  return get("/consumerProject/queryAuditListById", params);
};
// 删除
export const errorMsgQueryByCid = (params) => {
  return get("/errorMsg/queryByCid", params);
};
// 删除
export const errorMsgQueryBuildNameList = (params) => {
  return get("/errorMsg/queryBuildNameList", params);
};
// 删除
export const errorMsgQueryProjectNameList = (params) => {
  return get("/errorMsg/queryProjectNameList", params);
};
// 删除
export const errorMsgAdd = (params) => {
  return post("/errorMsg/add", params);
};

// 删除
export const consumerCancelBind = (params) => {
  return get("/consumer/cancelBind", params);
};
// 删除
export const userRegister = (params) => {
  return post("/user/register", params);
};
// 删除
export const wxUserQueryById = (params) => {
  return get("/wxUser/queryById", params);
};
// 删除
export const houseInfoQueryById = (params) => {
  return get("/houseInfo/queryById", params);
};
// 删除
export const houseInfoAdd = (params) => {
  return post("/houseInfo/add", params);
};
// 删除
export const houseInfoEdit = (params) => {
  return post("/houseInfo/edit", params);
};
// 删除
export const consumerEditStatus = (params) => {
  return get("/consumer/editStatus", params);
};

// 删除
export const consumerReportList = (params) => {
  return get("/consumerReport/list", params);
};
// 删除
export const consumerReportMake = (params) => {
  return get("/consumerReport/make", params);
};

// 删除
export const consumerReportQueryById = (params) => {
  return get("/consumerReport/queryById", params);
};
// 删除
export const ability_photoQueryByName = (params) => {
  return get("/ability_photo/queryByName", params);
};
// 删除
export const ability_photoQueryByConsumerId = (params) => {
  return get("/ability_photo/queryByConsumerId", params);
};
// 删除
export const ability_photoAddOrEdit = (params) => {
  return post("/ability_photo/addOrEdit", params);
};
// 删除
export const consumerReportUpdateById = (params) => {
  return post("/consumerReport/updateById", params);
};
// 删除
export const consumerReportUpdate = (params) => {
  return post("/consumerReport/update", params);
};
// 删除
export const consumerReportInsertOrUpdate = (params) => {
  return post("/ConsumerReportQrcode/insertOrUpdate", params);
};




