import axios from "axios";
import { Base64 } from "js-base64";
import { errMsg } from "./useMsg";
import { getToken, removeToken } from "./userToken";
import {BASEURL} from '@/config/index'
axios.defaults.baseURL = BASEURL;
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
/* 请求拦截器 */
axios.interceptors.request.use(
  (config) => {
    let token = getToken();
    if (token) config.headers['token'] = token;
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    console.log(error);
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use((response) => {
  const message = response.data.msg || "未知错误";
  //如果是401则跳转到登录页面
  if (response.data.data?.errorcode === "403") {
    removeToken();
    window.location.reload();
  }
  // 如果请求为非200否者默认统一处理
  // if (response.data.code !== 200 && !response.data.status) {
  //   errMsg(message);
  // }
  return response;
});

export default axios;

export async function get(url, params) {
  let {data} = await axios.get(url, { params });
  return data;
}

export async function post(url, params) {
  let { data } = await axios.post(url, params);
  return data;
}
