/**
 * 免登录的白名单
 */
export const WHITE_LIST = ['/login']

/**
 * 暂无图片的占位图片路径
 */
export const NO_IMG_PATH = "/noimg.jpg"

/**
 * AJAX超时时间
 */
export const AJAX_TIMEOUT = 5000

export const BASEURL = "https://slhgz.tzyl12349.com/prod-api"
// export const BASEURL = "http://fnca9u.natappfree.cc" 

/**
 * 登录时存TOKEN的KEY
 */
export const TOKEN_KEY = 'token'
export const USER_INFO = 'userInfo'

export const menuPathList = [
    {
        name: "首页",
        path: "/home",
    },
    {
        name: "服务工单",
        path: "/serviceOrder",
    },
    {
        name: "工单管理",
        path: "/serviceOrder/workOrderManagement",
    },
    {
        name: "服务派单",
        path: "/serviceOrder/serviceDispatch",
    },
    {
        name: "监管核查",
        path: "home",
    },
    {
        name: "服务机构",
        path: "home",
    },
    {
        name: "服务中心",
        path: "home",
    },
    {
        name: "服务人员",
        path: "home",
    },
    {
        name: "服务对象",
        path: "home",
    },
    {
        name: "平台用户",
        path: "home",
    },
    {
        name: "服务项目",
        path: "home",
    },
];