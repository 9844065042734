import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let moduleFiles = require.context('./modules', true, /\.js$/)
let modules = moduleFiles.keys().reduce((modules, module_path) => {
    let module_name = module_path.replace(/\.\/(.+)\.js/, '$1')
    modules[module_name] = moduleFiles(module_path).default
    return modules
}, {})
export default new Vuex.Store({
  modules
});
