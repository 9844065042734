import { getToken, setToken, setUserInfo } from "@/utils/userToken";
import { login, menu } from "@/api/index.js";
import { sucMsg } from "@/core/mixins/useMsg";
import { removeCookie } from '@/core/mixins/useCookie'
export default {
    namespaced: true,
    state: {
        token: getToken(),
        userInfo: {},
        menuList: [],
        selectedKeys: [],
    },
    mutations: {

        SET_TOKEN(state, val) {
            state.token = val
        },
        LOG_OUT(state) {
            state.userInfo = {};
            state.token = "";
            removeCookie("token");
            removeCookie("userInfo");
        },
        SET_USERINFO(state, val) {
            console.log(val)
            state.userInfo = val
        },
        SET_MENULIST(state, val) {
            state.menuList = val
        },
        SET_SELECTEDKEYS(state, val) {
            state.selectedKeys = val
        },
    },
    actions: {
        async login({ commit }, loginModel) {
            let { msg, data, status } = await login(loginModel);
            if (status) {
                sucMsg(msg)
                setToken(data.token);
                setUserInfo(JSON.stringify(data.user));
                commit('SET_TOKEN', data.token)
                commit('SET_USERINFO', data.user)
            }
            console.log(status)
            return {
                status,msg
            }
        },
        logout({ commit }) {
            commit("LOG_OUT");
        },
        async getMenu({ commit }) {
            let { status, data } = await menu();
            if (status) {
                commit("SET_MENULIST", JSON.parse(data).data)
            }
            return JSON.parse(data).data
        }
    }
};
