import { message } from "ant-design-vue";
export const sucMsg = (value) => {
  message.success(value);
};

export const errMsg = (value) => {
  message.error(value);
};
export const warnMsg = (value) => {
  message.warning(value);
};
